.poll {
  margin-top: 16px;
  font-size: 14px;

  li {
    margin-bottom: 10px;
    position: relative;
  }

  &__chart {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: inline-block;
    border-radius: 4px;
    background: hsla(var(--primary-text-color_hsl), 0.1);
    &.leading { background: hsla(var(--primary-text-color_hsl), 0.15); }
  }

  &__text {
    position: relative;
    display: flex;
    padding: 6px 0;
    line-height: 18px;
    cursor: default;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    color: var(--primary-text-color--faint);

    input[type=radio],
    input[type=checkbox] {
      display: none;
    }

    > span:last-child {
      flex: 1;
    }

    .autossugest-input {
      flex: 1 1 auto;
    }

    input[type=text] {
      @apply border border-solid border-primary-600;
      display: block;
      box-sizing: border-box;
      width: 100%;
      font-size: 14px;
      outline: 0;
      font-family: inherit;
      border-radius: 4px;
      padding: 6px 10px;

      &:focus {
        @apply border border-solid border-primary-500;
      }
    }

    &.selectable {
      cursor: pointer;
    }

    &.editable {
      display: flex;
      align-items: center;
      overflow: visible;

      .autosuggest-input {
        width: 100%;
      }
    }
  }

  &__input {
    @apply border border-solid border-primary-600;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    flex: 0 0 auto;
    margin-right: 10px;
    top: -1px;
    border-radius: 50%;
    vertical-align: middle;

    &.checkbox {
      border-radius: 4px;
    }

    &.active {
      border-color: $valid-value-color;
      background: $valid-value-color;
    }

    &:active,
    &:focus,
    &:hover {
      border-width: 4px;
      background: none;
    }

    &::-moz-focus-inner {
      outline: 0 !important;
      border: 0;
    }

    &:focus,
    &:active {
      outline: 0 !important;
    }
  }

  &__number {
    display: inline-block;
    width: 36px;
    font-weight: 700;
    padding: 0 10px;
    text-align: right;
  }

  &.voted &__number {
    width: 52px;
    padding-left: 8px;
    flex: 0 0 52px;
  }

  &__vote__mark {
    float: left;
    color: var(--highlight-text-color);
    line-height: 18px;
  }

  &__link {
    display: inline;
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    color: var(--primary-text-color);
    text-decoration: underline;
    font-size: inherit;

    &:hover {
      text-decoration: none;
    }

    &:active,
    &:focus {
      background-color: hsla(var(--primary-text-color_hsl), 0.1);
    }
  }

  .button {
    height: 36px;
    padding: 0 16px;
    margin-right: 10px;
    font-size: 14px;
  }

  &__cancel {
    @apply h-5;

    .svg-icon {
      @apply h-5 w-5;
    }
  }
}

.compose-form__poll-wrapper {
  border-top: 1px solid var(--foreground-color);

  ul {
    padding: 10px;
  }

  .button.button-secondary {
    @apply h-auto py-1.5 px-2.5 text-primary-600 dark:text-primary-400 border-primary-600;
  }

  li {
    display: flex;
    align-items: center;

    .poll__text {
      flex: 0 0 auto;
      width: calc(100% - (23px + 6px));
      margin-right: 6px;
    }
  }

  select {
    @apply border border-solid border-primary-600 bg-white dark:bg-slate-800;
    box-sizing: border-box;
    font-size: 14px;
    display: inline-block;
    width: auto;
    outline: 0;
    font-family: inherit;
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: auto 16px;
    border-radius: 4px;
    padding: 6px 10px;
    padding-right: 30px;
  }

  .icon-button.disabled {
    color: var(--brand-color);
  }
}

.muted .poll {
  color: var(--primary-text-color);

  &__chart {
    background: hsla(var(--brand-color_hsl), 0.2);

    &.leading {
      background: hsla(var(--brand-color_hsl), 0.2);
    }
  }
}
